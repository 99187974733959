<template>
  <div class="wrapper login-wrapper">
    <div
      class="container justify-center items-center pb-64"
      :class="
        $store.state.auth.loggedIn &&
        !$store.state.userData.user.is_email_verified
          ? 'mt-12'
          : ''
      "
    >
      <div
        class="flex flex-col items-center mt-5 rounded-xl bg-white py-5 px-8 shadow"
      >
        <div class="flex flex-col justify-center items-center mb-3 pt-3">
          <h2>Täname, et aktiveerisid konto!</h2>
          <p>Tere tulemast Gewodo platvormile!</p>
          <div class="w-full flex justify-evenly mt-3">
            <button
              v-if="$store.state.auth.status.loggedIn"
              @click="goToDashboard()"
              class="new-button-green"
            >
              <span class="label">Töölauale</span>
            </button>
            <button
              v-if="!$store.state.auth.status.loggedIn"
              @click="$router.push('/')"
              class="new-button-green"
            >
              <span class="label">Tagasi esilehele</span>
            </button>
          </div>
        </div>

        <button
          @click="$router.push('/login')"
          class="new-button-pending py-2"
          v-if="!$store.state.auth.status.loggedIn"
        >
          <span class="label text-xl">Logi sisse</span>
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import RequestHandler from "@/assets/mixins/RequestHandler";
export default {
  data() {
    return {};
  },
  mixins: [RequestHandler],
  mounted() {
    this.activateAccount();
  },
  methods: {
    activateAccount() {
      this.$store.state.loading = true;
      this.apiRequest(
        "authentication/" +
          this.$route.params.tokenPrefix +
          "/" +
          this.$route.params.token +
          "/",
        "get",
        false
      ).then(
        res => {
          if (res.status === 200) {
            this.$store.state.loading = false;
          }
          if (this.$store.state.auth.status.loggedIn) {
            this.$store.dispatch("userData/retrieveProfileData");
          }
        },
        err => {
          this.$store.state.loading = false;
          console.error(err);
        }
      );
    },
    goToDashboard() {
      this.$store.dispatch("userData/retrieveProfileData");
      this.$store.dispatch("companyData/retrieveCompanyData");
      this.$store.dispatch("notifications/retrieveNotifications");
      this.$router.push("/calendar");
    }
  }
};
</script>
